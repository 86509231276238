import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PhoneIcon from '@material-ui/icons/Phone';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Header from "components/Header/Header.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinksBuerscheFalkenApotheke.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//Images
import logo from "assets/img/falkenapotheke/logofalkenapotheke.png";
import Favorite from "@material-ui/icons/Favorite";

const dashboardRoutes = [];

// we define a minimal configuration
const klaroConfig = {
  acceptAll: true,
  translations: {
    de: {
      googleAnalytics: {
        title: "Google Analytics",
        description: "Sammeln von Besucherstatistiken",
      },
      purposes: {
        analytics: "Besucher-Statistiken",
      }
    },
    en: {
      googleAnalytics: {
        title: "Google Analytics",
        description: "Collecting of visitor statistics",
      },
      purposes: {
        analytics: "Analytics",
      }
    }
  },
  services: [
    {
      name: 'googleAnalytics',
      title: 'Google Analytics ',
      purposes: ['analytics'],
    },
  ],
};


class HeaderSection extends React.Component {
  state = {openmessage: ''}

  anchorElShop = null;
  anchorElRecipes = null;
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      openLeft: false,
      openTop: false,
      openShop: false,
      openRecipes: false,
      openRight: false
    };

  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true
    });
  }

  checkTime() {
    var now = new Date();

    var message = "jetzt geöffnet";
    var dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, ... 6 = Saturday
    var hour = now.getHours(); // 0 = 12am, 1 = 1am, ... 18 = 6pm

    var start = 8 * 60;
    var end =  18 * 60 + 30;
    var saturdayStart = 9 * 60;
    var saturdayEnd =  14 * 60;
    const date = new Date();
    now = date.getHours() * 60 + date.getMinutes();

    // check if it's a weekday between 8am and 6:30pm
    if (dayOfWeek > 0 && dayOfWeek < 6 && (start <= now && now <= end)) {
      if (hour > 16) {
        message = 'schließt um 18:30 Uhr';
      }
    } else if (dayOfWeek > 0 && dayOfWeek < 6 && (start <= now && now <= end)) {
      message = 'jetzt geöffnet';
    } else if (dayOfWeek === 6 && (saturdayStart <= now && saturdayEnd <= end)) {
      message = 'jetzt geöffnet';
      if (hour > 11) {
        message = 'schließt um 14:00 Uhr';
      }
    } else if (dayOfWeek === 5 && now >= end) {
      message = 'öffnet Samstags um 9:00 Uhr';
    } else if (dayOfWeek === 6 && hour < 9 && hour > 5) {
      message = 'öffnet um 9:00';
    } else if (dayOfWeek === 6 && hour > 11) {
      message = 'schließt um 14:00 Uhr';
    } else if (dayOfWeek === 0 ) {
      message = 'öffnet Montags um 8:00 Uhr';
    } else {
      message = 'jetzt geschlossen';
      if (hour > 6) {
        message = 'öffnet um 8:00 Uhr';
      }
    }
    this.setState({
      openmessage: message
    });
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const klaro = require("klaro");
      window.klaro = klaro;
      window.klaroConfig = klaroConfig
      klaro.setup(klaroConfig);
    }
    this.checkTime();
    this.timer = setInterval(() => {  this.checkTime() }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const {classes, ...rest} = this.props;
    return (
        <>
        <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="Dom-Apotheke"
            brandimage={logo}
            href="SchreinersApotheken"
            rightLinks={<HeaderLinks/>}
            fixed
            changeColorOnScroll={{
              height: 0,
              color: "white"
            }}
            {...rest}
        />
        <Parallax filter small image={require("assets/img/Banner2.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 style={{color: "#555"}} className={classes.title}>Ein starkes Team in Buer <Favorite className={classes.icon} /></h1>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h3 style={{color: "#555"}}>{this.state.openmessage}</h3>
                    <p style={{color: "#555"}}>
                      Montag bis Freitag 08:00 - 18:30<br />
                      Samstag 09:00 - 14:00<br />
                      Sonntag geschlossen<br />
                      <br />
                      Goldbergplatz 1/ Hochstr. 1<br/>
                      45894 Gelsenkirchen<br />
                      <PhoneIcon style={{ fontSize: 12 }}/><a href={'tel:+4920933727'}> +49 (0)209/33727</a><br />
                    </p>
                    <br/>

                    <Button
                        color="success"
                        href="https://www.meineapotheke.de/shop/buersche-falken-apotheke-gelsenkirchen-45894"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <ShoppingCartIcon/>
                      zum Shop
                    </Button>
                    <Button
                        color="danger"
                        href="https://www.meineapotheke.de/shop/buersche-falken-apotheke-gelsenkirchen-45894/rezept-einloesen"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <ReceiptIcon/>
                      Rezept einlösen
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        </>
    );
  }
}

export default withStyles(landingPageStyle)(HeaderSection);
