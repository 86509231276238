import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// @material-ui/icons

// React icon

// core components
import Footer from "components/Footer/FooterBuerscheFalkenApotheke.jsx";
import Muted from "components/Typography/Muted.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page

//Images
import HeaderSection from "./Sections/HeaderSection";

class SchreinersApotheken extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div>
                <HeaderSection />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.section}>
                        <div className={classes.container}>
                            <Muted>
                                <br/>
                                <br/>
                                <h1>Datenschutz</h1>
                                <br/>
                                <br/>
                                <p><strong>Informationen &uuml;ber die Erhebung personenbezogener Daten<span
                                    className="Apple-converted-space">&nbsp;</span></strong></p>
                                <p><strong>auf unserer Webseite</strong></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><strong>Datenschutzerkl&auml;rung</strong></p>
                                <p><strong>I. Name und Anschrift des Verantwortlichen</strong></p>
                                <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler
                                    Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher
                                    Bestimmungen ist die:</p>
                                <p>Schreiners Apotheken</p>
                                <p>Buersche Falken Apotheke<br/> Goldbergplatz 1 / Hochstr. 1</p>
                                <p>45894 Gelsenkirchen</p>
                                <p>Inhaber: Apotheker Christian Schreiner e.K.<br/> Telefon: 0209 - 33727<br/> Telefax:
                                    0209 - 377699<br/> E-Mail: email@bf-apo.de<br/> Webseite: <a
                                        href="http://www.schreiners-apotheken.de">www.schreiners-apotheken.de</a>&nbsp;
                                </p>
                                <p><strong>II. Name und Anschrift des Datenschutzbeauftragten</strong></p>
                                <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
                                <p>&nbsp;</p>
                                <p>DeltaMed S&uuml;d GmbH &amp; Co.KG</p>
                                <p>Untere Gasse 9</p>
                                <p>71642 Ludwigsburg</p>
                                <p>Telefon: 07141 &ndash; 974 57-0</p>
                                <p>Telefax: 07141 &ndash; 974 57-14</p>
                                <p>E-Mail:&nbsp;<a href="mailto:dsb@deltamedsued.de">dsb@deltamedsued.de</a></p>
                                <p>Internet:&nbsp;<a href="http://www.deltamedsued.de/">www.deltamedsued.de</a></p>
                                <p>&nbsp;</p>
                                <p><strong>III. Allgemeines zur Datenverarbeitung</strong></p>
                                <p>1. Umfang der Verarbeitung personenbezogener Daten</p>
                                <p>Wir erheben und verwenden personenbezogene Daten unserer Nutzer grunds&auml;tzlich
                                    nur, soweit dies zur Bereitstellung einer funktionsf&auml;higen Website sowie
                                    unserer Inhalte und Leistungen erforderlich ist. Die Erhebung und Verwendung
                                    personenbezogener Daten unserer Nutzer erfolgt regelm&auml;&szlig;ig nur nach
                                    Einwilligung des Nutzers. Eine Ausnahme gilt in solchen F&auml;llen, in denen eine
                                    vorherige Einholung einer Einwilligung aus tats&auml;chlichen Gr&uuml;nden nicht
                                    m&ouml;glich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften
                                    gestattet ist.&nbsp;</p>
                                <p>2. Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten</p>
                                <p>Soweit wir f&uuml;r Verarbeitungsvorg&auml;nge personenbezogener Daten eine
                                    Einwilligung der betroffenen Person einholen, dient Art.&nbsp;6 Abs.&nbsp;1
                                    lit.&nbsp;a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.</p>
                                <p>Bei der Verarbeitung von personenbezogenen Daten, die zur Erf&uuml;llung eines
                                    Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient
                                    Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO als Rechtsgrundlage. Dies gilt auch
                                    f&uuml;r Verarbeitungsvorg&auml;nge, die zur Durchf&uuml;hrung vorvertraglicher
                                    Ma&szlig;nahmen erforderlich sind.</p>
                                <p>Soweit eine Verarbeitung personenbezogener Daten zur Erf&uuml;llung einer rechtlichen
                                    Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art.&nbsp;6
                                    Abs.&nbsp;1 lit.&nbsp;c DSGVO als Rechtsgrundlage.</p>
                                <p>F&uuml;r den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer
                                    anderen nat&uuml;rlichen Person eine Verarbeitung personenbezogener Daten
                                    erforderlich machen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;d DSGVO als
                                    Rechtsgrundlage.</p>
                                <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens
                                    oder eines Dritten erforderlich und &uuml;berwiegen die Interessen, Grundrechte und
                                    Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient
                                    Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO als Rechtsgrundlage f&uuml;r die
                                    Verarbeitung.&nbsp;</p>
                                <p>Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer Gesundheitsdaten ist Artikel 9 Absatz
                                    2 lit. h) DSGVO in Verbindung mit Paragraf 22 Absatz 1 Nr. 1 lit. b)
                                    Bundesdatenschutzgesetz.</p>
                                <p>3. Datenl&ouml;schung und Speicherdauer</p>
                                <p>Die personenbezogenen Daten der betroffenen Person werden gel&ouml;scht oder
                                    gesperrt, sobald der Zweck der Speicherung entf&auml;llt. Eine Speicherung kann
                                    dar&uuml;ber hinaus erfolgen, wenn dies durch den europ&auml;ischen oder nationalen
                                    Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften,
                                    denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
                                    L&ouml;schung der Daten erfolgt auch dann, wenn eine durch die genannten Normen
                                    vorgeschriebene Speicherfrist abl&auml;uft, es sei denn, dass eine Erforderlichkeit
                                    zur weiteren Speicherung der Daten f&uuml;r einen Vertragsabschluss oder eine
                                    Vertragserf&uuml;llung besteht.</p>
                                <p><strong>IV. Bereitstellung der Website und Erstellung von Logfiles</strong></p>
                                <p>1. Beschreibung und Umfang der Datenverarbeitung</p>
                                <p>Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
                                    Informationen vom Computersystem des aufrufenden Rechners.&nbsp;</p>
                                <p>Folgende Daten werden hierbei erhoben:</p>
                                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp; Informationen &uuml;ber den Browsertyp und die verwendete
                                    Version</p>
                                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp; Das Betriebssystem des Nutzers</p>
                                <p>(3)&nbsp;&nbsp;&nbsp;&nbsp; Den Internet-Service-Provider des Nutzers</p>
                                <p>(4)&nbsp;&nbsp;&nbsp;&nbsp; Die IP-Adresse des Nutzers</p>
                                <p>(5)&nbsp;&nbsp;&nbsp;&nbsp; Datum und Uhrzeit des Zugriffs</p>
                                <p>(6)&nbsp;&nbsp;&nbsp;&nbsp; Websites, von denen das System des Nutzers auf unsere
                                    Internetseite gelangt&nbsp;</p>
                                <p>(7)&nbsp;&nbsp;&nbsp;&nbsp; Websites, die vom System des Nutzers &uuml;ber unsere
                                    Website aufgerufen werden</p>
                                <p>Die Logfiles enthalten IP-Adressen oder sonstige Daten, die eine Zuordnung zu einem
                                    Nutzer erm&ouml;glichen. Dies k&ouml;nnte beispielsweise der Fall sein, wenn der
                                    Link zur Website, von der der Nutzer auf die Internetseite gelangt, oder der Link
                                    zur Website, zu der der Nutzer wechselt, personenbezogene Daten enth&auml;lt.</p>
                                <p>Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine
                                    Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers
                                    findet nicht statt.</p>
                                <p>2. Rechtsgrundlage f&uuml;r die Datenverarbeitung</p>
                                <p>Rechtsgrundlage f&uuml;r die vor&uuml;bergehende Speicherung der Daten und der
                                    Logfiles ist Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO.</p>
                                <p>3. Zweck der Datenverarbeitung</p>
                                <p>Die vor&uuml;bergehende Speicherung der IP-Adresse durch das System ist notwendig, um
                                    eine Auslieferung der Website an den Rechner des Nutzers zu erm&ouml;glichen.
                                    Hierf&uuml;r muss die IP-Adresse des Nutzers f&uuml;r die Dauer der Sitzung
                                    gespeichert bleiben.</p>
                                <p>Die Speicherung in Logfiles erfolgt, um die Funktionsf&auml;higkeit der Website
                                    sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur
                                    Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine
                                    Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
                                    statt.&nbsp;</p>
                                <p>In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung
                                    nach Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO.</p>
                                <p>4. Dauer der Speicherung</p>
                                <p>Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer
                                    Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur
                                    Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet
                                    ist.&nbsp;</p>
                                <p>Im Falle der Speicherung der Daten in Logfiles ist dies nach sp&auml;testens sieben
                                    Tagen der Fall. Eine dar&uuml;berhinausgehende Speicherung ist m&ouml;glich. In
                                    diesem Fall werden die IP-Adressen der Nutzer gel&ouml;scht oder verfremdet, sodass
                                    eine Zuordnung des aufrufenden Clients nicht mehr m&ouml;glich ist.</p>
                                <p>5. Widerspruchs- und Beseitigungsm&ouml;glichkeit</p>
                                <p>Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten
                                    in Logfiles ist f&uuml;r den Betrieb der Internetseite zwingend erforderlich. Es
                                    besteht folglich seitens des Nutzers keine Widerspruchsm&ouml;glichkeit.&nbsp;</p>
                                <p><strong>V. Verwendung von Cookies</strong></p>
                                <p>a) Beschreibung und Umfang der Datenverarbeitung</p>
                                <p>Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im
                                    Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers
                                    gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem
                                    Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enth&auml;lt eine
                                    charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers
                                    beim erneuten Aufrufen der Website erm&ouml;glicht.&nbsp;</p>
                                <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige
                                    Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach
                                    einem Seitenwechsel identifiziert werden kann.</p>
                                <p>In den Cookies werden dabei folgende Daten gespeichert und &uuml;bermittelt:</p>
                                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp; Spracheinstellungen</p>
                                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp; Log-In-Informationen</p>
                                <p>Wir verwenden auf unserer Website dar&uuml;ber hinaus Cookies, die eine Analyse des
                                    Surfverhaltens der Nutzer erm&ouml;glichen.&nbsp;</p>
                                <p>Auf diese Weise k&ouml;nnen folgende Daten &uuml;bermittelt werden:</p>
                                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp; H&auml;ufigkeit von Seitenaufrufen</p>
                                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp; Inanspruchnahme von Website-Funktionen</p>
                                <p>Die auf diese Weise erhobenen Daten der Nutzer werden durch technische Vorkehrungen
                                    pseudonymisiert. Daher ist eine Zuordnung der Daten zum aufrufenden Nutzer nicht
                                    mehr m&ouml;glich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen
                                    Daten der Nutzer gespeichert.</p>
                                <p>Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner &uuml;ber die
                                    Verwendung von Cookies zu Analysezwecken informiert und auf diese
                                    Datenschutzerkl&auml;rung verwiesen. Es erfolgt in diesem Zusammenhang auch ein
                                    Hinweis darauf, wie die Speicherung von Cookies in den Browsereinstellungen
                                    unterbunden werden kann.</p>
                                <p>b) Rechtsgrundlage f&uuml;r die Datenverarbeitung</p>
                                <p>Die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten unter
                                    Verwendung von Cookies ist Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO.</p>
                                <p>c) Zweck der Datenverarbeitung</p>
                                <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites
                                    f&uuml;r die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite
                                    k&ouml;nnen ohne den Einsatz von Cookies nicht angeboten werden. F&uuml;r diese ist
                                    es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt
                                    wird.</p>
                                <p>F&uuml;r folgende Anwendungen ben&ouml;tigen wir Cookies:</p>
                                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp; &Uuml;bernahme von Spracheinstellungen</p>
                                <p>Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualit&auml;t unserer
                                    Website und ihre Inhalte zu verbessern. Durch die Analyse-Cookies erfahren wir, wie
                                    die Website genutzt wird und k&ouml;nnen so unser Angebot stetig optimieren.</p>
                                <p>In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der
                                    personenbezogenen Daten nach Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO.</p>
                                <p>e)&nbsp;Dauer der Speicherung, Widerspruchs- und Beseitigungsm&ouml;glichkeit</p>
                                <p>Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer
                                    Seite &uuml;bermittelt. Daher haben Sie als Nutzer auch die volle
                                    Kontrolle &uuml;ber die Verwendung von Cookies. Durch eine &Auml;nderung der
                                    Einstellungen in Ihrem Internetbrowser k&ouml;nnen Sie die &Uuml;bertragung von
                                    Cookies deaktivieren oder einschr&auml;nken. Bereits gespeicherte Cookies
                                    k&ouml;nnen jederzeit gel&ouml;scht werden. Dies kann auch automatisiert erfolgen.
                                    Werden Cookies f&uuml;r unsere Website deaktiviert, k&ouml;nnen m&ouml;glicherweise
                                    nicht mehr alle Funktionen der Website vollumf&auml;nglich genutzt werden.</p>
                                <p><strong>VI. Kontaktformular und E-Mail-Kontakt</strong></p>
                                <p>1. Beschreibung und Umfang der Datenverarbeitung</p>
                                <p>Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches f&uuml;r die
                                    elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese
                                    M&ouml;glichkeit wahr, so werden die in der Eingabemaske eingegeben Daten an
                                    uns &uuml;bermittelt und gespeichert. Diese Daten sind:</p>
                                <p>Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:</p>
                                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp; Die IP-Adresse des Nutzers</p>
                                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp; Datum und Uhrzeit der Ausf&uuml;llung</p>
                                <p>F&uuml;r die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre
                                    Einwilligung eingeholt und auf diese Datenschutzerkl&auml;rung verwiesen.</p>
                                <p>Alternativ ist eine Kontaktaufnahme &uuml;ber die bereitgestellte E-Mail-Adresse
                                    m&ouml;glich. In diesem Fall werden die mit der E-Mail &uuml;bermittelten
                                    personenbezogenen Daten des Nutzers gespeichert.&nbsp;</p>
                                <p>Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten
                                    werden ausschlie&szlig;lich f&uuml;r die Verarbeitung der Konversation
                                    verwendet.</p>
                                <p>2. Rechtsgrundlage f&uuml;r die Datenverarbeitung&nbsp;</p>
                                <p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist bei Vorliegen einer
                                    Einwilligung des Nutzers Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a DSGVO.</p>
                                <p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten, die im Zuge
                                    einer &Uuml;bersendung einer E-Mail &uuml;bermittelt werden, ist Art.&nbsp;6
                                    Abs.&nbsp;1 lit.&nbsp;f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines
                                    Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung
                                    Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO.</p>
                                <p>3. Zweck der Datenverarbeitung</p>
                                <p>Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein
                                    zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt
                                    hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der
                                    Daten.</p>
                                <p>Die sonstigen w&auml;hrend des Absendevorgangs verarbeiteten personenbezogenen Daten
                                    dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit
                                    unserer informationstechnischen Systeme sicherzustellen.</p>
                                <p>4. Dauer der Speicherung</p>
                                <p>Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer
                                    Erhebung nicht mehr erforderlich sind. F&uuml;r die personenbezogenen Daten aus der
                                    Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail &uuml;bersandt
                                    wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer
                                    beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umst&auml;nden
                                    entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end
                                    gekl&auml;rt ist.</p>
                                <p>Die w&auml;hrend des Absendevorgangs zus&auml;tzlich erhobenen personenbezogenen
                                    Daten werden sp&auml;testens nach einer Frist von sieben Tagen gel&ouml;scht.</p>
                                <p>5. Widerspruchs- und Beseitigungsm&ouml;glichkeit</p>
                                <p>Der Nutzer hat jederzeit die M&ouml;glichkeit, seine Einwilligung zur Verarbeitung
                                    der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit
                                    uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit
                                    widersprechen. In einem solchen Fall kann die Konversation nicht fortgef&uuml;hrt
                                    werden.</p>
                                <p>Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden,
                                    werden in diesem Fall gel&ouml;scht.</p>
                                <p>In unserem Fall ist dies nach 7 Tagen der Fall.</p>
                                <p><strong>VII. Webanalyse durch Google Analytics</strong></p>
                                <p>1. Umfang der Verarbeitung personenbezogener Daten</p>
                                <p>Soweit Sie Ihre Einwilligung erkl&auml;rt haben, wird auf dieser Website Google
                                    Analytics eingesetzt, einen Webanalysedienst der Google Inc. (&bdquo;Google&ldquo;).
                                    Die Nutzung umfasst die Betriebsart &bdquo;Universal Analytics&ldquo;. Hierdurch ist
                                    es m&ouml;glich, Daten, Sitzungen und Interaktionen &uuml;ber mehrere Ger&auml;te
                                    hinweg einer pseudonymen User-ID zuzuordnen und so die Aktivit&auml;ten eines
                                    Nutzers ger&auml;te&uuml;bergreifend zu analysieren. Dieser Datenschutzhinweis wird
                                    zur Verf&uuml;gung gestellt von&nbsp;<a
                                        href="https://www.intersoft-consulting.de">www.intersoft-consulting.de</a>.</p>
                                <p>Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, Textdateien, die auf Ihrem
                                    Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
                                    erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre
                                    Benutzung dieser Website werden in der Regel an einen Server von Google in den
                                    USA &uuml;bertragen und dort gespeichert. Im Falle der Aktivierung der
                                    IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch
                                    innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen
                                    Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor
                                    gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server
                                    von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Wir weisen Sie darauf
                                    hin, dass auf dieser Webseite Google Analytics um eine IP-Anonymisierung erweitert
                                    wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu
                                    gew&auml;hrleisten. Die im Rahmen von Google Analytics von Ihrem
                                    Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google
                                    zusammengef&uuml;hrt. N&auml;here Informationen zu Nutzungsbedingungen und
                                    Datenschutz finden Sie unter&nbsp;<a
                                        href="https://www.google.com/analytics/terms/de.html">https://www.google.com/analytics/terms/de.html</a>&nbsp;bzw.
                                    unter&nbsp;<a
                                        href="https://policies.google.com/?hl=de">https://policies.google.com/?hl=de</a>.
                                </p>
                                <p>2. Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten</p>
                                <p>Die Rechtsgrundlage f&uuml;r den Einsatz von Google Analytics ist ihre Einwilligung
                                    gem&auml;&szlig;&nbsp;<a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1
                                        S.1 lit. a DSGVO</a>.</p>
                                <p>3. Zwecke der Verarbeitung</p>
                                <p><strong>&nbsp;</strong>Im Auftrag des Betreibers dieser Website wird Google diese
                                    Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
                                    Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit
                                    der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
                                    gegen&uuml;ber dem Websitebetreiber zu erbringen.</p>
                                <p><strong>Empf&auml;nger / Kategorien von Empf&auml;ngern</strong><br/> Der
                                    Empf&auml;nger der erhobenen Daten ist Google.</p>
                                <p><strong>&Uuml;bermittlung in Drittstaaten</strong><br/> Die personenbezogenen Daten
                                    werden unter dem EU-US Privacy Shield auf Grundlage des Angemessenheitsbeschlusses
                                    der Europ&auml;ischen Kommission in die USA &uuml;bermittelt. Das Zertifikat
                                    k&ouml;nnen Sie&nbsp;<a
                                        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI">hier</a>&nbsp;abrufen.
                                </p>
                                <p>4. Dauer der Datenspeicherung</p>
                                <p><strong>&nbsp;</strong>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B.
                                    User-ID) oder Werbe-IDs verkn&uuml;pften Daten werden nach 14 Monaten automatisch
                                    gel&ouml;scht. Die L&ouml;schung von Daten, deren Aufbewahrungsdauer erreicht ist,
                                    erfolgt automatisch einmal im Monat.</p>
                                <p><strong>Betroffenenrechte</strong><br/> Sie k&ouml;nnen Ihre Einwilligung jederzeit
                                    mit Wirkung f&uuml;r die Zukunft widerrufen, indem sie die Speicherung der Cookies
                                    durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
                                    Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche
                                    Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen.</p>
                                <p>Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und
                                    auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
                                    sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das&nbsp;<a
                                        href="https://tools.google.com/dlpage/gaoptout?hl=de">Browser-Add-on</a>&nbsp;herunterladen
                                    und installieren. Opt-Out-Cookies verhindern die zuk&uuml;nftige Erfassung Ihrer
                                    Daten beim Besuch dieser Website. Um die Erfassung durch Universal
                                    Analytics &uuml;ber verschiedene Ger&auml;te hinweg zu verhindern, m&uuml;ssen Sie
                                    das Opt-Out auf allen genutzten Systemen durchf&uuml;hren. Wenn Sie hier klicken,
                                    wird das Opt-Out-Cookie gesetzt:&nbsp;<strong>Google Analytics deaktivieren</strong>
                                </p>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </Muted>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withStyles(landingPageStyle)(SchreinersApotheken);
